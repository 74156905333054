<template>
  <div class="mt-8">
    <v-row>
      <v-col
        cols="12"
        md="12"
        class="headline pa-2"
        style="border-bottom:4px solid #d84315"
      >
        <b>Neue Berechnung "Katze-Krankenversicherung" starten</b>
        <span class="hidden-xs-only"
          >&nbsp;|&nbsp; inkl. AntragAnnahmecheck
        </span></v-col
      >
    </v-row>

    <div style="max-width: 888px">
      <v-row class="my-4">
        <v-col cols="12" md="3">Wie heisst Ihr Katze?</v-col>
        <v-col cols="12" md="9" class="pt-0">
          <v-text-field
            v-model="formRechner.name"
            label="Name Ihrer Katze"
          ></v-text-field
        ></v-col>
      </v-row>
      <v-row class="my-4">
        <v-col cols="12" md="3">Ihre Postleitzahl</v-col>
        <v-col cols="12" md="9" class="pt-0">
          <v-text-field
            v-model="formRechner.plz"
            width="100px"
            label="Postleitzahl"
          />
        </v-col>
      </v-row>

      <v-row class="my-4">
        <v-col cols="12" md="3">Geschlecht Ihrer Katze?</v-col>
        <v-col cols="12" md="9" class="pt-0">
          <v-radio-group v-model="formRechner.geschlecht">
            <v-radio value="1" label="Männlich" />
            <v-radio value="2" label="Weiblich" />
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row class="my-4">
        <v-col cols="12" md="3">Welche Rasse hat Ihre Katze?</v-col>
        <v-col cols="12" md="9" class="pt-0">
          <vue-simple-suggest
            v-if="rassen && !weissnicht"
            v-model="chosen"
            :list="rassen"
            :filter-by-query="true"
          >
            <!-- Filter by input text to only show the matching results -->
          </vue-simple-suggest>
          <div class="my-4" v-if="chosen == '' && rassen && !weissnicht">
            {{ chosen }}
          </div>
          <v-checkbox
            v-model="weissnicht"
            value="1"
            label="Ich kenne die Rasse nicht bzw. ist keine Rassenkatze"
          />
        </v-col>
      </v-row>

      <v-row class="my-4">
        <v-col cols="12" md="3">Geburtstag Ihrer Katze</v-col>
        <v-col cols="12" md="9" class="pt-0">
          <input-geburtstag-select
            v-on:update:year="yearChanged"
            v-on:update:month="monthChanged"
            v-on:update:day="dayChanged"
            :year.sync="jahr"
            :month.sync="monat"
            :day.sync="tag"
        /></v-col>
      </v-row>

      <v-row class="my-4">
        <v-col cols="12" md="6">Ist Ihre Katze aktuell gesund?</v-col>
        <v-col cols="12" md="2" class="pt-0">
          <v-radio-group v-model="formRechner.ber_annahme_krank">
            <v-row>
              <v-col cols="12" md="6"> <v-radio :value="2" label="Ja"/></v-col>
              <v-col cols="12" md="6"><v-radio :value="1" label="Nein"/></v-col>
            </v-row> </v-radio-group
        ></v-col>
      </v-row>

      <v-row class="my-4">
        <v-col cols="12" md="6"
          >Wurde Ihre Katze in den letzten 6 Monaten operiert?</v-col
        >
        <v-col cols="12" md="2" class="pt-0">
          <v-radio-group v-model="formRechner.ber_annahme_op6m">
            <v-row>
              <v-col cols="12" md="6"><v-radio :value="1" label="Ja" /> </v-col>
              <v-col cols="12" md="6"><v-radio :value="2" label="Nein"/></v-col>
            </v-row> </v-radio-group
        ></v-col>
      </v-row>
      <v-row class="my-4">
        <v-col cols="12" md="6">Ist Ihre Katze kastriert?</v-col>
        <v-col cols="12" md="2" class="pt-0">
          <v-radio-group v-model="formRechner.kastration">
            <v-row>
              <v-col cols="12" md="6"
                ><v-radio
                  value="1"
                  label="Ja
"
              /></v-col>
              <v-col cols="12" md="6"
                ><v-radio value="0" label="Nein" />
              </v-col>
            </v-row> </v-radio-group
        ></v-col>
      </v-row>

      <v-row class="my-4">
        <v-col cols="12" md="6">Ist Ihre Katze gechiped?</v-col>
        <v-col cols="12" md="2" class="pt-0">
          <v-radio-group v-model="formRechner.chip">
            <v-row>
              <v-col cols="12" md="6"><v-radio value="1" label="Ja"/></v-col>
              <v-col cols="12" md="6"
                ><v-radio value="0" label="Nein" />
              </v-col>
            </v-row> </v-radio-group
        ></v-col>
      </v-row>

      <v-row class="my-4">
        <v-col cols="12" md="6">Ist Ihre Katze ein Freigänger?</v-col>
        <v-col cols="12" md="2" class="pt-0">
          <v-radio-group v-model="formRechner.outdoor">
            <v-row>
              <v-col cols="12" md="6"><v-radio value="1" label="Ja"/></v-col>
              <v-col cols="12" md="6"
                ><v-radio value="0" label="Nein. " />
              </v-col>
            </v-row> </v-radio-group
        ></v-col>
      </v-row>

      <v-row class="my-4">
        <v-col cols="12" md="6">Ist Ihre Katze geimpft?</v-col>
        <v-col cols="12" md="2" class="pt-0">
          <v-radio-group v-model="formRechner.impfung">
            <v-row>
              <v-col cols="12" md="6"><v-radio value="1" label="Ja"/></v-col>
              <v-col cols="12" md="6"
                ><v-radio value="0" label="Nein" />
              </v-col>
            </v-row> </v-radio-group
        ></v-col>
      </v-row>

      <v-row class="my-4">
        <v-col cols="12" md="6">Soll Homöopathie mitversichert werden?</v-col>
        <v-col cols="12" md="2" class="pt-0">
          <v-radio-group v-model="formRechner.homoe">
            <v-row>
              <v-col cols="12" md="6"><v-radio value="1" label="Ja"/></v-col>
              <v-col cols="12" md="6"><v-radio value="0" label="Nein"/></v-col>
            </v-row> </v-radio-group
        ></v-col>
      </v-row>

      <v-row class="my-4" v-if="showfehler">
        <v-col cols="12" md="3"><b class="red--text">Achtung</b></v-col>
        <v-col cols="12" md="9">{{ fehlerarray }}</v-col>
      </v-row>

      <v-btn
        class="nocaps mr-4 white--text"
        color="#d84315"
        depressed
        tile
        style="font-size: 24px;width:100%;height: 48px;"
        @click="getBerechnungErgebnis()"
        >hier klicken und eigenen Vergleich starten</v-btn
      ><br /><br />
    </div>
  </div>
</template>

<script>
  import VueSimpleSuggest from "vue-simple-suggest";
  import "vue-simple-suggest/dist/styles.css";

  import InputGeburtstagSelect from "./InputGeburtstagSelect";
  import DateHelper from "./../../DateHelper.js";
  export default {
    name: "index-rechner-katze",
    components: {
      VueSimpleSuggest,

      InputGeburtstagSelect,
      DateHelper,
    },

    data: function() {
      return {
        showfehler: false,
        dateChanged: 0,
        formok: false,
        fehlerarray: false,
        weissnicht: false,
        tarife: false,
        rassen: false,
        chosen: "",
        fullrassen: false,
        tag: new Date().getDate(),
        monat: new Date().getMonth() + 1,
        jahr: new Date().getYear() + 1900,
        token: false,
        //   items: ["Affenpinscher", "Afghan Hound"],
        formRechner: {
          rasse: "0",
          geschlecht: "1",
          kastration: "0",
          outdoor: "0",
          alter: 0,
          type: "katze",
          tierart: 2,
          mischling: "0",
          plz: "",
          chip: "1",
          impfung: "1",
          name: "",
          geburtstag: "",
          homoe: "0",
          ber_annahme_op6m: 2,
          ber_annahme_krank: 2,
        },
      };
    },
    created() {
      this.setTitle();
      this.getRassenKatze();
    },

    methods: {
      setTitle() {
        this.$log.debug("title");
        document.title = "Katzen-Krankenversicherungen vergleichen";
      },
      getRassenKatze() {
        this.$store
          .dispatch("getRassenKatze")
          .then((resp) => {
            if (resp.data.fullrassen) {
              this.rassen = resp.data.rassen;
              this.fullrassen = resp.data.fullrassen;
            }
          })
          .catch((err) => {
            this.$log.error(err);
          });
      },
      dayChanged(day) {
        // this.$log.debug(`day changed: ${day}`)
        this.tag = day;
        this.calcAge();
      },
      monthChanged(month) {
        this.monat = month;
        this.calcAge();
      },
      yearChanged(year) {
        this.jahr = year;
        this.calcAge();
      },
      calcAge() {
        let age = DateHelper.calcAge(this.tag, this.monat, this.jahr);
        if (age < 0) {
          age = 0;
        }
        this.dateChanged = this.dateChanged + 1;
        this.$log.debug("calcAge", this.dateChanged);
        this.formRechner.alter = age;
        this.formRechner.geburtstag =
          this.jahr + "-" + this.monat + "-" + this.tag;
      },

      simpleSuggestionList() {
        if (this.rassen) {
          return this.rasssen;
        }
        //
        // return this.items;
        //return ["Vue.js", "React.js", "Angular.js"];
      },

      searchStringInArray2() {
        for (var j = 0; j < this.rassen.length; j++) {
          if (this.fullrassen[j].ras_name.match(this.chosen2))
            return this.fullrassen[j].ras_id;
        }
        return 0;
      },
      searchStringInArray() {
        for (var j = 0; j < this.rassen.length; j++) {
          if (this.fullrassen[j].ras_name.match(this.chosen))
            return this.fullrassen[j].ras_id;
        }
        return 0;
      },
      testSearch() {
        let id;
        id = this.searchStringInArray();
        this.$log.debug("string", id);
      },
      checkFormFehler() {
        if (this.formRechner.plz == "" || this.formRechner.plz.length < 3) {
          this.formok = false;
          this.showfehler = true;
          this.fehlerarray =
            "Bitte geben Sie Ihre PLZ ein. Einige Anbieter kalkulieren den Preis auch anhand der PLZ.";
          return;
        }
        if (this.dateChanged == 1) {
          this.formok = false;
          this.showfehler = true;
          this.fehlerarray =
            "Bitte geben Sie den Geburtstag Ihres Tieres ein (geschätzt)";
          return;
        }

        this.formok = true;
      },
      getBerechnungErgebnis() {
        this.checkFormFehler();
        if (this.formok) {
          //this.formRechner.rasse = this.searchStringInArray();
          if (this.chosen) {
            this.formRechner.rasse = this.searchStringInArray();
          }
          this.$store.commit("start_loading_full");
          this.$store
            .dispatch("getBerechnungErgebnis", this.formRechner)
            .then((resp) => {
              if (resp.data.token) {
                this.$store.commit("stop_loading_full");
                this.$router.push({
                  name: "vergleich",
                  params: {
                    token: resp.data.token,
                  },
                });
              }
            })
            .catch((err) => {
              this.$log.error(err);
            });
        }
      },
    },
  };
</script>
